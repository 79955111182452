.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  font-size: calc(10px + 2vmin);
  color: black;
  margin-top: 50px;
}
.app-title {
  max-height: 10vh;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'BD-Nippori';
  src: url('./fonts/BD-Nippori.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('./fonts/Nunito-VariableFont_wght.ttf');
  font-weight: normal;
  font-style: normal;
}

.title {
  font-family: 'BD-Nippori';
  font-weight: 400;
  font-style: normal;
  line-height: 1.6em;
  text-transform: none;
  letter-spacing: 0em;
  font-size: 16px;
}

@media (min-width: 750px) {
  .title {    
  font-size: 36px;
  }
}

@media (min-width: 320px) {
  .title {    
  font-size: 24px;
  }
}


.background-white {
  background-color: white;
}

.nav-item {
  margin-left: 10px;
  margin-right: 10px;
  font-family: 'Nunito';
  color: black;
  font-weight: 600;
  font-style: normal;
  text-transform: none;
  letter-spacing: .2em;
  font-size: 17px;
}

.body {
  min-height: 75vh;
}

.color-black {
  color: black;
}